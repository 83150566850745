<template>
  <v-card flat>
    <v-toolbar flat>
      <!-- <v-progress-linear
      v-if="progress"
      indeterminate
      :size="30"
      :width="7"
      color="purple"
    ></v-progress-linear> -->

      <template v-slot:extension>
        <v-tabs v-model="tabs" align-with-title @change="tabchange">
          <v-tab href="#users">
            Utilisateurs {{ "(" + users.length + ")" }}</v-tab
          >
          <v-tab href="#profils"
            >Profils {{ "(" + profiles.length + ")" }}</v-tab
          >

          <v-tabs-slider color="pink"></v-tabs-slider>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <v-tabs-items v-model="tabs">
        <v-tab-item :value="'profils'">
          <v-container fluid v-if="tabs == 'profils'">
            <v-row>
              <v-col cols="4">
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      autocomplete="off"
                      type="text"
                      hide-details
                      single-line
                      solo-inverted
                      clearable
                      dense
                      class="pr-5"
                      prepend-inner-icon="mdi-magnify"
                      placeholder="Chercher"
                      style="max-width: 600px; margin-top: 10px"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-fab-transition>
                      <v-btn dense fab @click="new_item" :disabled="modify">
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                    </v-fab-transition></v-col
                  >
                </v-row>
                <v-list class="scroll" max-height="700">
                  <v-list-item-group
                    v-model="selectedProfile"
                    @change="select_profile"
                    v-if="!modify"
                  >
                    <v-list-item v-for="profile in profiles" :key="profile.id">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          profile.profile
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col cols="8">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card>
                    <v-toolbar dark dense class="mb-4 pr-8">
                      <v-btn
                        text
                        @click="cancel_edit"
                        v-if="editedItem.id != 1 && modify"
                      >
                        Annuler
                        <v-icon right> mdi-cancel </v-icon>
                      </v-btn>
                      <v-btn
                        text
                        @click="delete_profile"
                        v-if="editedItem.id != 1 && !modify"
                        :disabled="nb_users > 0"
                      >
                        Supprimer
                        <v-icon right> mdi-delete </v-icon>
                      </v-btn>
                      <v-btn
                        text
                        @click="edit_profile"
                        v-if="editedItem.id != 1 && !modify"
                      >
                        Modifier
                        <v-icon right> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn
                        text
                        @click="save_profile"
                        v-if="editedItem.id != 1 && modify"
                      >
                        Enregistrer
                        <v-icon right> mdi-content-save </v-icon>
                      </v-btn>
                    </v-toolbar>

                    <v-card-text>
                      <div>
                        <v-text-field
                          autocomplete="off"
                          ref="profile"
                          outlined
                          dense
                          v-model="editedItem.profile"
                          label="Profil"
                          :rules="[(v) => !!v || 'Nom Profil obligatoire']"
                          class="mt-15"
                          :readonly="!modify"
                        ></v-text-field>
                      </div>
                      <div>
                        <v-select
                          v-model="profile_modules"
                          :items="modules"
                          :item-text="'module'"
                          :item-value="'id'"
                          label="Module"
                          :readonly="!modify"
                          multiple
                          chips
                          deletable-chips
                        >
                        </v-select>
                      </div>
                      <div>
                        <v-autocomplete
                          v-if="editedItem.id != 1"
                          v-model="profile_auths"
                          :items="profile_module_auths"
                          :item-text="'autorisation'"
                          :item-value="'code'"
                          chips
                          deletable-chips
                          multiple
                          label="Autorisations"
                          :readonly="!modify"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index <= 2">
                              <span>{{ item.autorisation }}</span>
                            </v-chip>
                            <span
                              v-if="index === 3"
                              class="grey--text text-caption"
                            >
                              (+{{ profile_auths.length - 3 }} autres)
                            </span>
                          </template>
                        </v-autocomplete>
                      </div>
                      <div>
                        <v-select
                          v-if="editedItem.id != 1"
                          v-model="profile_natures"
                          :items="natures"
                          :item-text="'nature'"
                          :item-value="'id'"
                          label="Nature"
                          :readonly="!modify"
                          multiple
                          @change="profile_nature_change"
                          chips
                        ></v-select>
                      </div>
                      <div>
                        <v-select
                          v-if="editedItem.id != 1"
                          v-model="profile_product_types"
                          :items="product_types"
                          :item-text="'label'"
                          :item-value="'id'"
                          label="Types de produits autorisés"
                          :readonly="!modify"
                          multiple
                          chips
                          deletable-chips
                        >
                        </v-select>
                      </div>
                      <div>
                        <v-select
                          v-if="editedItem.id != 1"
                          v-model="profile_tier_types"
                          :items="tier_types"
                          :item-text="'tier_type'"
                          :item-value="'id'"
                          label="Types de tiers autorisés"
                          :readonly="!modify"
                          multiple
                          chips
                          deletable-chips
                        >
                        </v-select>
                      </div>
                      <div>
                        <v-select
                          v-if="editedItem.id != 1"
                          v-model="profile_alerts"
                          :items="alerts"
                          :item-text="'label'"
                          :item-value="'id'"
                          label="Alertes autorisés"
                          :readonly="!modify"
                          multiple
                          chips
                          deletable-chips
                        >
                        </v-select>
                      </div>
                    </v-card-text>

                    <v-card-actions class="ma-3">
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <v-tab-item :value="'users'">
          <v-container fluid v-if="tabs == 'users'">
            <v-row>
              <v-col cols="4">
                <v-row no-gutters dense>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      autocomplete="off"
                      type="text"
                      hide-details
                      single-line
                      solo-inverted
                      clearable
                      dense
                      class="pr-5"
                      prepend-inner-icon="mdi-magnify"
                      placeholder="Chercher"
                      style="max-width: 600px; margin-top: 10px"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-fab-transition>
                      <v-btn dense fab @click="new_item" :disabled="modify">
                        <v-icon> mdi-plus </v-icon>
                      </v-btn>
                    </v-fab-transition></v-col
                  >
                </v-row>
                <v-list shaped class="scroll" max-height="700">
                  <v-list-item-group
                    v-model="selectedUser"
                    @change="select_user"
                    v-if="!modify"
                  >
                    <v-list-item v-for="user in users" :key="user.id">
                      <v-list-item-content>
                        <v-list-item-title>{{ user.name }}</v-list-item-title>
                        <v-list-item-subtitle
                          v-if="user.durration || user.last_login_at"
                        >
                          {{
                            (user.durration
                              ? "Connecté : " +
                                user.durration.substr(0, 2) +
                                " Heures " +
                                user.durration.substr(3, 2) +
                                " Minutes " +
                                user.durration.substr(6, 2) +
                                " Secondes "
                              : "Dernière connection : " +
                                datefr(user.last_login_at, true)) +
                            " depuis " +
                            user.last_login_ip
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="user.durration ? true : false"
                          color="deep-purple accent-4"
                          readonly
                        ></v-checkbox>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-col>
              <v-col cols="8">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card>
                    <v-toolbar dark dense class="mb-4 pr-8">
                      <v-btn text @click="cancel_edit" v-if="modify">
                        Annuler
                        <v-icon right> mdi-cancel </v-icon>
                      </v-btn>

                      <v-btn text @click="edit_user" v-if="!modify">
                        Modifier
                        <v-icon right> mdi-pencil </v-icon>
                      </v-btn>

                      <v-btn text @click="save_user" v-if="modify">
                        Enregistrer
                        <v-icon right> mdi-content-save </v-icon>
                      </v-btn>
                    </v-toolbar>

                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="3"
                          md="4"
                          lg="4"
                          v-if="editedItem.id == 1"
                        >
                          <v-text-field
                            autocomplete="off"
                            ref="username"
                            outlined
                            dense
                            v-model="editedItem.name"
                            label="Nom"
                            :rules="[
                              (v) => !!v || 'Nom utilisateur obligatoire',
                            ]"
                            :readonly="!modify"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="6" lg="6">
                          <v-autocomplete
                            v-model="editedItem.agent_id"
                            :items="agents"
                            :item-text="'nom'"
                            :item-value="'id'"
                            :filter="customFilter"
                            outlined
                            dense
                            :rules="[(v) => !!v || 'Employé obligatoire']"
                            label="Employé"
                            :readonly="!modify || editedItem.id == 1"
                            v-if="editedItem.id != 1"
                          >
                            <template slot="selection" slot-scope="data">
                              <!-- HTML that describe how select should render selected items -->
                              {{ data.item.matricule + "-" + data.item.nom }}
                            </template>
                            <template slot="item" slot-scope="data">
                              <!-- HTML that describe how select should render selected items -->
                              {{ data.item.matricule + "-" + data.item.nom }}
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3" md="4" lg="4">
                          <v-text-field
                            autocomplete="off"
                            v-if="editedItem.id != 1"
                            ref="email"
                            outlined
                            dense
                            v-model="editedItem.email"
                            label="E-mail"
                            :rules="[
                              (v) => !!v || 'E-mail obligatoire',
                              (v) => /.+@.+/.test(v) || 'E-mail non valide',
                            ]"
                            :readonly="!modify"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="4" md="4" lg="4">
                          <v-text-field
                            autocomplete="off"
                            outlined
                            dense
                            v-model="editedItem.username"
                            label="UserName"
                            :rules="[(v) => !!v || 'UserName obligatoire']"
                            :readonly="!modify"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4">
                          <v-select
                            v-model="editedItem.profile_id"
                            :items="profiles"
                            :item-text="'profile'"
                            :item-value="'id'"
                            label="Profile"
                            outlined
                            dense
                            :readonly="!modify || editedItem.id == 1"
                            @change="profile_change"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          lg="2"
                          v-if="editedItem.id > 0"
                        >
                          <v-text-field
                            autocomplete="off"
                            ref="username"
                            outlined
                            dense
                            v-model.number="editedItem.attempt"
                            label="Tentative de connection"
                            :readonly="!modify"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                          md="2"
                          lg="2"
                          v-if="editedItem.id > 0"
                        >
                          <v-checkbox
                            label="Connecté"
                            class="mt-1"
                            v-model="editedItem.connected"
                            :readonly="!modify"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="8" md="8">
                          <v-text-field
                            autocomplete="off"
                            v-if="modify && tabs == 'users'"
                            ref="psw"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            hint="Minimum 8 characteres"
                            outlined
                            dense
                            v-model="editedItem.password"
                            label="Mot de passe"
                            :rules="[
                              (v) => !!v || 'Mot de passe obligatoire',
                              (v) =>
                                (v && v.length >= 8) ||
                                editedItem.id == 1 ||
                                'Longueur minimale 8',
                            ]"
                            counter
                            @click:append="show = !show"
                            @input="pws_change++"
                            :readonly="!modify"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <v-checkbox
                            label="Changer MP"
                            v-model="mpc"
                            v-if="modify && tabs == 'users'"
                            class="mt-3"
                          ></v-checkbox>
                        </v-col>
                      </v-row>

                      <div>
                        <selecttree
                          :items="structures"
                          v-model="user_structures_selected"
                          :value="user_structures_selected"
                          :label="'Structures autorisés'"
                          :item_disabled="'locked'"
                          :clearable="modify"
                          :key="kus"
                          :deletable_chips="false"
                          :readonly="!modify"
                          :select_type="'independent'"
                          :selectable="true"
                          @change="parent_change"
                        >
                        </selecttree>
                      </div>

                      <div>
                        <v-select
                          v-if="
                            editedItem.id != 1 && editedItem.profile_id != 1
                          "
                          v-model="user_modules_selected"
                          :items="user_modules"
                          :item-text="'module'"
                          :item-value="'id'"
                          label="Module"
                          :readonly="!modify"
                          multiple
                          chips
                          deletable-chips
                        >
                        </v-select>
                      </div>
                      <div>
                        <v-autocomplete
                          v-if="
                            editedItem.id != 1 && editedItem.profile_id != 1
                          "
                          v-model="user_auths_selected"
                          :items="user_modules_auths"
                          :item-text="'autorisation'"
                          :item-value="'code'"
                          chips
                          deletable-chips
                          multiple
                          label="Autorisations"
                          :readonly="!modify"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index <= 2">
                              <span>{{ item.autorisation }}</span>
                            </v-chip>
                            <span
                              v-if="index === 3"
                              class="grey--text text-caption"
                            >
                              (+{{ user_auths_selected.length - 3 }} autres)
                            </span>
                          </template>
                        </v-autocomplete>
                      </div>
                      <div>
                        <v-select
                          v-if="editedItem.profile_id != 1"
                          v-model="user_natures_selected"
                          :items="user_natures"
                          :item-text="'nature'"
                          :item-value="'id'"
                          label="Nature des Produits"
                          :readonly="!modify || editedItem.id == 1"
                          @change="user_nature_change"
                          multiple
                          chips
                        ></v-select>
                      </div>
                      <div>
                        <v-select
                          v-if="editedItem.profile_id != 1"
                          v-model="user_product_types_selected"
                          :items="user_product_types"
                          :item-text="'label'"
                          :item-value="'id'"
                          label="Types de produits autorisés"
                          :readonly="!modify"
                          multiple
                          chips
                          deletable-chips
                        >
                        </v-select>
                      </div>
                      <div>
                        <v-select
                          v-if="editedItem.profile_id != 1"
                          v-model="user_tier_types_selected"
                          :items="user_tier_types"
                          :item-text="'tier_type'"
                          :item-value="'id'"
                          label="Types de tiers autorisés"
                          :readonly="!modify"
                          multiple
                          chips
                          deletable-chips
                        >
                        </v-select>
                      </div>
                      <div>
                        <v-select
                          v-model="user_depots_selected"
                          :items="depots"
                          :item-text="'label'"
                          :item-value="'id'"
                          label="Depots autorisés"
                          :readonly="!modify"
                          multiple
                          chips
                          deletable-chips
                          v-if="editedItem.profile_id != 1"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="item.label"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-html="item.Hierarchy"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-select>
                      </div>
                      <div>
                        <v-autocomplete
                          v-model="user_alerts_selected"
                          :items="user_alerts"
                          :item-text="'label'"
                          :item-value="'id'"
                          label="Alertes autorisés"
                          :readonly="!modify"
                          multiple
                          chips
                          deletable-chips
                          v-if="editedItem.profile_id != 1"
                        >
                        </v-autocomplete>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <confirmdialog ref="confirm" />
  </v-card>
</template>

<script>
function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}
import PROFILES from "../graphql/Users/PROFILES.gql";
import CREATE_PROFILE from "../graphql/Users/CREATE_PROFILE.gql";
import UPDATE_PROFILE from "../graphql/Users/UPDATE_PROFILE.gql";
import DELETE_PROFILE from "../graphql/Users/DELETE_PROFILE.gql";
import CREATE_USER from "../graphql/Users/CREATE_USER.gql";
import UPDATE_USER from "../graphql/Users/UPDATE_USER.gql";
import USER_DETAIL from "../graphql/Users/USER_DETAIL.gql";
import UPDATE_PROFILE_DETAIL from "../graphql/Users/UPDATE_PROFILE_DETAIL.gql";

import UPDATE_USER_PWD from "../graphql/Users/UPDATE_USER_PWD.gql";

import { myBL } from "print/data";

import { autorisations } from "@/utils/functions.js";

export default {
  components: {
    selecttree: () => import("../components/SelectTree.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  data: () => ({
    search: null,
    mpc: false,
    pws_change: 0,
    show: false,
    show1: false,
    valid: true,
    modify: false,
    selectedProfile: -2,
    selectedUser: -2,
    tabs: null,
    profiles: [],
    users: [],
    agents: [],
    modules: [],
    product_types: [],
    tier_types: [],
    allproducts_types: [],
    natures: [
      { id: "0", nature: "Produit" },
      { id: "1", nature: "Service" },
    ],
    kus: 2000,
    depots: [],
    alerts: [],
    structures: [],
    list_auth: [],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    lastprofile: -1,
    lastuser: -1,
    nb_users: 0,
    user_product_types: [],
    user_tier_types: [],
    user_alerts: [],
    user_modules: [],
    user_natures: [],

    user_product_types_selected: [],
    user_tier_types_selected: [],
    user_modules_selected: [],
    user_natures_selected: [],
    user_depots_selected: [],
    user_alerts_selected: [],
    user_structures_selected: [],
    user_auths_selected: [],

    profile_auths: [],
    profile_modules: [],
    profile_natures: [],
    profile_product_types: [],
    profile_tier_types: [],
    profile_alerts: [],
  }),
  computed: {
    profile_module_auths() {
      let list = [];
      if (this.profile_modules)
        list = this.list_auth.filter(
          (elm) =>
            this.profile_modules.includes(elm.module) || elm.module == "0"
        );
      return list;
    },
    user_modules_auths() {
      let list = [];
      if (this.user_modules_selected)
        list = this.list_auth.filter(
          (elm) =>
            this.user_modules_selected.includes(elm.module) || elm.module == "0"
        );
      return list;
    },
  },
  methods: {
    cancel_edit() {
      if (this.tabs == "profils") {
        this.selectedProfile = this.lastprofile;
        this.select_profile();
      } else {
        this.selectedUser = this.lastuser;
        this.select_user();
      }
      this.modify = false;
    },
    parent_change(item) {
      this.user_structures_selected = item;
    },
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    profile_nature_change() {
      this.product_types = this.allproducts_types.filter((elm) =>
        this.profile_natures.includes(elm.nature)
      );
    },
    user_nature_change() {
      this.product_types = this.allproducts_types.filter((elm) =>
        this.user_natures_selected.includes(elm.nature)
      );
    },
    customFilter(item, queryText) {
      let textlist = [];
      let l = ["matricule", "nom"];
      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    profile_change() {
      this.user_auths = [];
      this.user_product_types = [];
      this.user_tier_types = [];
      this.user_alerts = [];
      this.user_natures = [];
      this.user_modules = [];

      this.user_auths_selected = [];
      this.user_product_types_selected = [];
      this.user_tier_types_selected = [];
      this.user_natures_selected = [];
      this.user_depots_selected = [];
      this.user_alerts_selected = [];
      this.user_structures_selected = [];
      this.user_modules_selected = [];

      this.profile_auths = [];
      this.profile_product_types = [];
      this.profile_tier_types = [];
      this.profile_alerts = [];
      this.profile_modules = [];
      this.profile_natures = [];

      if (this.editedItem.profile_id) {
        let i = this.profiles.findIndex(
          (elm) => elm.id == this.editedItem.profile_id
        );
        if (i >= 0) {
          if (this.profiles[i].autorisations)
            this.profile_auths = this.profiles[i].autorisations.split(",");
          if (this.profiles[i].product_types)
            this.profile_product_types =
              this.profiles[i].product_types.split(",");
          if (this.profiles[i].tier_types)
            this.profile_tier_types = this.profiles[i].tier_types.split(",");
          if (this.profiles[i].alerts)
            this.profile_alerts = this.profiles[i].alerts.split(",");

          if (this.profiles[i].modules)
            this.profile_modules = this.profiles[i].modules.split(",");
          if (this.profiles[i].nature)
            this.profile_natures = this.profiles[i].nature.split(",");
        }
        //verifier modules profile
        for (let index = 0; index < this.modules.length; index++) {
          const element = this.modules[index];
          let i = this.profile_modules.findIndex((elm) => elm == element.id);

          if (i >= 0) {
            element["disabled"] = true;
            this.user_modules_selected.push(element.id);
          }
          this.user_modules.push(element);
        }

        if (this.editedItem.modules) {
          this.editedItem.modules.split(",").forEach((element) => {
            this.user_modules_selected.push(element);
          });
        }

        //verifier auth profile
        for (let index = 0; index < this.user_modules_auths.length; index++) {
          const element = this.user_modules_auths[index];
          let i = this.profile_auths.findIndex((elm) => elm == element.code);

          if (i >= 0) {
            element["disabled"] = true;
            this.user_auths_selected.push(element.code);
          }
          this.user_auths.push(element);
        }
        if (this.editedItem.autorisations) {
          this.editedItem.autorisations.split(",").forEach((element) => {
            this.user_auths_selected.push(element);
          });
        }

        //verifier natures profile

        for (let index = 0; index < this.natures.length; index++) {
          const element = this.natures[index];
          let i = this.profile_natures.findIndex((elm) => elm == element.id);

          if (i >= 0) {
            element["disabled"] = true;
            this.user_natures_selected.push(element.id);
          }
          this.user_natures.push(element);
        }
        if (this.editedItem.nature) {
          this.editedItem.nature.split(",").forEach((element) => {
            if (
              this.user_natures_selected.findIndex((elm) => elm == element) ==
              -1
            )
              this.user_natures_selected.push(element);
          });
        }
        this.user_nature_change();
        //verifier produits profile

        for (let index = 0; index < this.product_types.length; index++) {
          const element = this.product_types[index];
          let i = this.profile_product_types.findIndex(
            (elm) => elm == element.id
          );

          if (i >= 0) {
            element["disabled"] = true;
            this.user_product_types_selected.push(element.id);
          }
          this.user_product_types.push(element);
        }
        if (this.editedItem.product_types) {
          this.editedItem.product_types.split(",").forEach((element) => {
            this.user_product_types_selected.push(element);
          });
        }
        //verifier alerts profile
        for (let index = 0; index < this.alerts.length; index++) {
          const element = this.alerts[index];
          let i = this.profile_alerts.findIndex((elm) => elm == element.id);

          if (i >= 0) {
            element["disabled"] = true;
            this.user_alerts_selected.push(element.id);
          }
          this.user_alerts.push(element);
        }

        if (this.editedItem.alerts) {
          this.editedItem.alerts.split(",").forEach((element) => {
            this.user_alerts_selected.push(element);
          });
        }
        //verifier tier types profile
        for (let index = 0; index < this.tier_types.length; index++) {
          const element = this.tier_types[index];
          let i = this.profile_tier_types.findIndex((elm) => elm == element.id);

          if (i >= 0) {
            element["disabled"] = true;
            this.user_tier_types_selected.push(element.id);
          }
          this.user_tier_types.push(element);
        }
        if (this.editedItem.tier_types) {
          this.editedItem.tier_types.split(",").forEach((element) => {
            this.user_tier_types_selected.push(element);
          });
        }
      }
    },
    tabchange() {
      this.modify = false;
      if (this.tabs == "profils") {
        this.selectedProfile = 0;
        this.select_profile();
      }
      if (this.tabs == "users") {
        this.selectedUser = 0;
        this.select_user();
      }
      if (this.search != "") this.search = "";
    },
    select_profile() {
      this.profile_auths = [];
      this.profile_modules = [];
      this.profile_products = [];
      this.profile_tier_types = [];
      this.profile_alerts = [];
      this.profile_natures = [];
      this.nb_users = 0;
      if (this.profiles.length > 0)
        this.editedItem = this.profiles[this.selectedProfile]
          ? this.profiles[this.selectedProfile]
          : {};
      if (this.editedItem) {
        this.nb_users = this.editedItem.users.length;
        if (this.editedItem.nature)
          this.profile_natures = this.editedItem.nature.split(",");
        this.profile_nature_change();
        if (this.editedItem.product_types)
          this.profile_product_types = this.editedItem.product_types.split(",");
        if (this.editedItem.tier_types)
          this.profile_tier_types = this.editedItem.tier_types.split(",");
        if (this.editedItem.alerts)
          this.profile_alerts = this.editedItem.alerts.split(",");
        if (this.editedItem.modules)
          this.profile_modules = this.editedItem.modules.split(",");
        if (this.editedItem.autorisations)
          this.profile_auths = this.editedItem.autorisations.split(",");
      }
      for (let index = 0; index < this.modules.length; index++) {
        const element = this.modules[index];
        element["disabled"] = false;
      }
      //verifier auth profile
      for (let index = 0; index < this.user_modules_auths.length; index++) {
        const element = this.user_modules_auths[index];
        element["disabled"] = false;
      }

      //verifier natures profile
      for (let index = 0; index < this.natures.length; index++) {
        const element = this.natures[index];
        element["disabled"] = false;
      }

      //verifier produits profile
      for (let index = 0; index < this.product_types.length; index++) {
        const element = this.product_types[index];
        element["disabled"] = false;
      }

      //verifier tier types profile
      for (let index = 0; index < this.tier_types.length; index++) {
        const element = this.tier_types[index];
        element["disabled"] = false;
      }
    },
    select_user() {
      if (this.users.length > 0 && this.selectedUser >= 0) {
        this.editedItem = this.users[this.selectedUser];
        this.editedItem.connected = this.editedItem.durration ? true : false;
        this.profile_change();
      }
      if (this.editedItem) {
        if (this.editedItem.depots)
          this.user_depots_selected = this.editedItem.depots.split(",");
        if (this.editedItem.structures) {
          this.user_structures_selected = this.editedItem.structures.split(",");
        }
        this.kus++;
      }
    },
    new_item() {
      this.lastprofile = this.selectedProfile;
      this.lastuser = this.selectedUser;

      this.editedItem = {};

      this.modify = true;
      if (this.tabs == "profils") {
        this.profile_auths = [];
        this.profile_modules = [];
        this.profile_products = [];
        this.profile_tier_types = [];
        this.profile_alerts = [];
        this.profile_natures = [];
        this.selectedProfile = -1;
        this.$refs.profile.focus();
      }
      if (this.tabs == "users") {
        this.user_auths_selected = [];
        this.user_modules_selected = [];
        this.user_product_types_selected = [];
        this.user_tier_types_selected = [];
        this.user_natures_selected = [];
        this.user_depots_selected = [];
        this.user_alerts_selected = [];
        this.user_structures_selected = [];
        this.selectedUser = -1;
        this.$refs.username.focus();
      }
    },
    async delete_profile() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer ce profile?"
        )
      ) {
        this.$apollo
          .mutate({
            mutation: DELETE_PROFILE,
            variables: {
              id: this.editedItem.id,
            },
          })
          .then(() => {
            this.profiles.splice(this.selectedProfile, 1);
          })
          .catch((error) => {
            this.snackbar_color = "error";
            this.snackbar_text = error.message;
            this.snackbar = true;
          });
      }
    },
    async edit_user() {
      this.modify = true;
      this.lastuser = this.selectedUser;
      this.$refs.username.focus();
    },
    async save_user() {
      if (this.$refs.form.validate()) {
        this.modify = false;
        let add_auth = [];
        let add_modules = [];
        //let add_natures = [];
        let add_product_types = [];
        let add_tier_types = [];
        let add_alerts = [];

        for (let index = 0; index < this.user_auths_selected.length; index++) {
          if (
            !this.profile_auths.find(
              (elm) => elm == this.user_auths_selected[index]
            )
          )
            add_auth.push(this.user_auths_selected[index]);
        }

        for (
          let index = 0;
          index < this.user_modules_selected.length;
          index++
        ) {
          if (
            !this.profile_modules.find(
              (elm) => elm == this.user_modules_selected[index]
            )
          )
            add_modules.push(this.user_modules_selected[index]);
        }
        // for (
        //   let index = 0;
        //   index < this.user_natures_selected.length;
        //   index++
        // ) {
        //   if (
        //     !this.profile_natures.find(
        //       (elm) => elm == this.user_natures_selected[index]
        //     )
        //   )
        //     add_natures.push(this.user_natures_selected[index]);
        // }
        for (
          let index = 0;
          index < this.user_product_types_selected.length;
          index++
        ) {
          if (
            !this.profile_product_types.find(
              (elm) => elm == this.user_product_types_selected[index]
            )
          )
            add_product_types.push(this.user_product_types_selected[index]);
        }
        for (
          let index = 0;
          index < this.user_tier_types_selected.length;
          index++
        ) {
          if (
            !this.profile_tier_types.find(
              (elm) => elm == this.user_tier_types_selected[index]
            )
          )
            add_tier_types.push(this.user_tier_types_selected[index]);
        }
        for (let index = 0; index < this.user_alerts_selected.length; index++) {
          if (
            !this.profile_alerts.find(
              (elm) => elm == this.user_alerts_selected[index]
            )
          )
            add_alerts.push(this.user_alerts_selected[index]);
        }

        if (this.selectedUser < 0) {
          let v = {
            input: {
              name: this.editedItem.name,
              agent_id: this.editedItem.agent_id,
              username: this.editedItem.username,
              email: this.editedItem.email,
              password: this.editedItem.password,
              password_confirmation: this.editedItem.password,
              profile_id: this.editedItem.profile_id,
              autorisations: add_auth.toString(),
              modules: add_modules.toString(),
              nature: this.user_natures_selected.toString(),
            },
          };
          let r = await this.maj(CREATE_USER, v);

          if (r) {
            this.editedItem.id = r.register.tokens.user.id;
            if (this.editedItem.agent_id)
              this.editedItem.name = this.agents.find(
                (elm) => elm.id == this.editedItem.agent_id
              ).nom;
            this.users.push(this.editedItem);
            v = {
              user_id: this.editedItem.id,
              depots: this.user_depots_selected,

              structures: this.user_structures_selected,
              products_types: add_product_types,
              tiers_types: add_tier_types,
              alerts: add_alerts,
              create_uid: this.$store.state.me.id,
            };
            await this.maj(USER_DETAIL, v);
            this.snackbar_color = "success";
            this.snackbar_text = "Utilisateur ajouté avec succes";
            this.snackbar = true;
          }
        } else {
          if (this.pws_change > 0) {
            let v = {
              pws: {
                username: this.editedItem.username,
                password: this.editedItem.password,
                mpc: this.mpc ? 1 : 0,
              },
            };
            let r = await this.maj(UPDATE_USER_PWD, v);
            if (r) {
              this.pws_change = 0;
              this.snackbar_color = "success";
              this.snackbar_text = "Données mis a jour avec succes";
              this.snackbar = true;
            }
          }
          let v = {
            user: {
              id: this.editedItem.id,
              name: this.editedItem.name,
              agent_id: this.editedItem.agent_id,
              username: this.editedItem.username,
              email: this.editedItem.email,
              profile_id: this.editedItem.profile_id,
              autorisations: add_auth.toString(),
              modules: add_modules.toString(),
              nature: this.user_natures_selected.toString(),
              connected_at: this.editedItem.connected
                ? this.editedItem.connected_at
                : null,
              attempt: parseInt(this.editedItem.attempt),
            },
          };
          let r = await this.maj(UPDATE_USER, v);
          if (r) {
            this.editedItem.password = r.updateUser.password;
            if (this.editedItem.agent_id)
              this.editedItem.name = this.agents.find(
                (elm) => elm.id == this.editedItem.agent_id
              ).nom;
            v = {
              user_id: this.editedItem.id,
              depots: this.user_depots_selected,
              structures: this.user_structures_selected,
              products_types: add_product_types,
              tiers_types: add_tier_types,
              alerts: add_alerts,
              create_uid: this.$store.state.me.id,
            };
            await this.maj(USER_DETAIL, v);
            this.users.splice(this.selectedUser, 1, this.editedItem);
            this.snackbar_color = "success";
            this.snackbar_text = "Données mis a jour avec succes";
            this.snackbar = true;
            //problem numero 3 produit et tier
          }
        }
      }
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async edit_profile() {
      this.lastprofile = this.selectedProfile;
      this.modify = true;
      this.$refs.profile.focus();
    },
    search_user() {
      let i = this.users.findIndex((elm) =>
        elm.name.toLowerCase().includes(this.search.toLowerCase())
      );
      if (i >= 0) this.selectedUser = i;
    },

    async save_profile() {
      if (this.$refs.form.validate()) {
        this.modify = false;
        this.editedItem.autorisations = this.profile_auths.toString();
        this.editedItem.modules = this.profile_modules.toString();
        this.editedItem.nature = this.profile_natures.toString();
        if (this.selectedProfile < 0) {
          let v = {
            profile: this.editedItem.profile,
            autorisations: this.editedItem.autorisations,
            modules: this.editedItem.modules,
            nature: this.editedItem.nature,
          };
          let r = await this.maj(CREATE_PROFILE, v);
          if (r) {
            this.editedItem.id = r.createProfile.id;
            v = {
              profile_id: this.editedItem.id,
              products_types: this.profile_product_types,
              tiers_types: this.profile_tier_types,
              alerts: this.profile_alerts,
              create_uid: this.$store.state.me.id,
            };
            await this.maj(UPDATE_PROFILE_DETAIL, v);
            this.snackbar_color = "success";
            this.snackbar_text = "Profile ajouté avec succes";
            this.snackbar = true;
            this.profiles.push(this.editedItem);
          }
        } else {
          let v = {
            id: this.editedItem.id,
            profile: this.editedItem.profile,
            autorisations: this.editedItem.autorisations,
            modules: this.editedItem.modules,
            nature: this.editedItem.nature,
          };
          let r = await this.maj(UPDATE_PROFILE, v);
          if (r) {
            v = {
              profile_id: this.editedItem.id,
              products_types: this.profile_product_types,
              tiers_types: this.profile_tier_types,
              alerts: this.profile_alerts,
              create_uid: this.$store.state.me.id,
            };
            await this.maj(UPDATE_PROFILE_DETAIL, v);
            this.snackbar_color = "success";
            this.snackbar_text = "Données mis a jour avec succes";
            this.snackbar = true;
            this.profiles.splice(this.selectedProfile, 1, this.editedItem);
          }
        }
      }
    },
  },
  watch: {
    search: debounce(function () {
      if (this.tabs == "profils") {
        if (this.search.trim() !== "") {
          this.profiles = this.original_profils.filter((x) =>
            x.description?.toLowerCase().includes(this.search.toLowerCase())
          );
        } else {
          this.profiles = this.original_profils;
        }
      } else {
        if (this.search.trim() !== "") {
          this.users = this.original_users.filter((x) =>
            x.name?.toLowerCase().includes(this.search.toLowerCase())
          );
        } else {
          this.users = this.original_users;
        }
      }
    }, 1000),
  },
  async mounted() {
    let r = await this.maj(PROFILES);
    if (r) {
      this.profiles = r.profiles;

      this.users =
        this.$store.state.me.id == "1"
          ? r.users
          : r.users.filter((elm) => elm.id != "1");
      this.allproducts_types = r.products_types;
      this.tier_types = r.tiertypes;
      this.depots = r.depots;
      this.alerts = r.alerts_list;
      this.structures = r.structures;
      this.modules = [
        { id: "1", module: "Achat" },
        { id: "2", module: "Vente" },
        { id: "3", module: "Gestion des Stocks" },
        { id: "4", module: "Production" },
        { id: "5", module: "Controle Qualité" },
        { id: "6", module: "Trésorerie" },
        { id: "7", module: "GED" },
      ];
      this.agents = r.agents;
      //si blanc
      if (myBL == "b")
        this.list_auth = autorisations.filter(
          (elm) =>
            elm.code != "05021" && elm.code != "01042" && elm.code != "01043"
        );
      else this.list_auth = autorisations;

      this.selectedProfile = 0;
      this.selectedUser = 0;
      this.original_users = this.users;
      this.original_profils = this.profiles;
      this.select_user();
    }
  },
};
</script>
